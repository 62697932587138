const ArrayPdf = [
{
    id:1,
    Name:"101",
    Pdf:"../pdf/101.pdf"
},
{
    id:2,
    Name:"102",
    Pdf:"../pdf/102.pdf"
},
{
    id:3,
    Name:"103",
    Pdf:"../pdf/103.pdf"
},
{
    id:4,
    Name:"104",
    Pdf:"../pdf/104.pdf"
},
{
    id:5,
    Name:"105",
    Pdf:"../pdf/105.pdf"
},

]
export default ArrayPdf;