const Galleryi = [

    {
        id: 1,
        image: '../news/news5.jpeg',

    },
    {
        id: 2,
        image: '../news/news3.jpeg',
     
    },
    {
        id: 3,
        image: '../news/news6.jpeg',
      
    },
    {
        id: 4,
        image: '../news/news1.jpeg',
       
    },
    {
        id: 5,
        image: '../news/news4.jpeg',
       
    },
    {
        id: 6,
        image: '../news/news7.jpeg',
       
    },
    {
        id: 7,
        image: '../news/news2.jpeg',
       
    },
   
    
    
    
        
    ]
    export default Galleryi;