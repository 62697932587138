const Galleryi = [

{
    id: 1,
    image: '../img/sports.jpeg',
    catogoery: 'Sport'
},
{
    id: 2,
    image: '../vip-img/basketball.jpeg',
    catogoery: 'Sport'
},
{
    id: 3,
    image:'../vip-img/basketball2.jpeg',
    catogoery: 'Sport'
},
{
    id: 4,
    image: '../img/fancy.jpg',
    catogoery: 'Dance'
},
{
    id: 5,
    image:  '../vip-img/culture.jpeg',
    catogoery: 'Dance'
},
{
    id: 6,
    image:  '../vip-img/culture2.jpeg',
    catogoery: 'Dance'
},
{
    id: 7,
    image: '../vip-img/sport.jpeg',
    catogoery: 'Sport'
},
{
    id: 8,
    image: '../vip-img/culture3.jpeg',
    catogoery: 'Dance'
},
{
    id: 9,
    image: '../vip-img/culture4.jpeg',
    catogoery: 'Dance'
},

{
    id: 10,
    image:  '../vip-img/culture5.jpeg',
    catogoery: 'Dance'
},
{
    id: 11,
    image:  '../vip-img/activity.jpeg',
    catogoery: 'Activity'
},
{
    id: 12,
    image:  '../vip-img/activity2.jpeg',
    catogoery: 'Activity'
},
{
    id: 13,
    image:  '../vip-img/activity3.jpeg',
    catogoery: 'Activity'
},
{
    id: 14,
    image:  '../vip-img/activity4.jpeg',
    catogoery: 'Activity'
},
{
    id: 15,
    image:  '../vip-img/foodstall.jpeg',
    catogoery: 'Activity'
},
{
    id: 15,
    image:  '../vip-img/sir.jpeg',
    catogoery: 'Activity'
},
{
    id: 15,
    image:  '../vip-img/activity5.jpeg',
    catogoery: 'Activity'
},
{
    id: 16,
    image:  '../vip-img/activity6.jpeg',
    catogoery: 'Activity'
},
{
    id: 17,
    image:  '../vip-img/activity7.jpeg',
    catogoery: 'Activity'
},



    
]
export default Galleryi;
